import {
	ViewListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "HISTORY TEACHER",
	desc: "Ask me history questions.",
	category: "Content",
	Icon: ViewListIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "blue-400",
	toColor: "purple-400",

	to: "/ai/history",
	api: "/ai/history",

	output: {
		title: "Review",
		desc: "Finished review",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Ask me history questions.",
		desc: "When was the cival war?",
		// n: 1,
		prompts: [{ 
				title: "Content", 
				attr: "content",  
				value: "", 
				placeholder: "Enter text here", 
				label: "",
				type: "textarea",
				maxLength: 600,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "When was the civil war?",
			},
		],
		example: {
			output: "The American Civil War took place from 1861 to 1865.",
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj

