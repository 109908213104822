import {
	ViewListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Recipe Maker",
	desc: "Make a recipe from list of ingredients.",
	category: "Personal",
	Icon: ViewListIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "blue-400",
	toColor: "purple-400",

	to: "/ai/recipe",
	api: "/ai/recipe",

	output: {
		title: "Recipe",
		desc: "Best outcome.",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Entry Text",
		desc: "this is a test.",
		// n: 1,
		prompts: [{ 
				title: "Content", 
				attr: "content",  
				value: "", 
				placeholder: "Pancakes peppers onions.", 
				label: "",
				type: "textarea",
				maxLength: 600,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Pancakes peppers onions.",
			},
		],
		example: {
			output: "1. Preheat a griddle or large frying pan over medium heat, 2. In a bowl, combine 1 cup of pancake mix with 1/4 cup of water. ,3. Add in 1/4 cup of diced peppers and onions. Stir until everything is combined. , 4. Scoop 1/4 cup batter onto the griddle or pan for each pancake. Cook until bubbles appear on the surface and the bottoms are golden brown, about 2 minutes per side. Serve warm!",
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj

