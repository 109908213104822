import {
	ViewListIcon,
} from '@heroicons/react/solid'


const obj = {

	title: "Email Maker ",
	desc: "Turn small details into a professionl email.",
	category: "Content",
	Icon: ViewListIcon,
	// tags: [],
	permissions: ['user'],
	
	fromColor: "blue-400",
	toColor: "purple-400",

	to: "/ai/example",
	api: "/ai/example",

	output: {
		title: "Finished",
		desc: "Email output",
		Icon: false,
		color: "blue",
	},

	prompts: [{
		title:"Email input",
		desc: "Input small details about the email.",
		// n: 1,
		prompts: [{ 
				title: "Content", 
				attr: "content",  
				value: "", 
				placeholder: "Write an email to...", 
				label: "",
				type: "textarea",
				maxLength: 600,
				// max: 100,
				min: 3,
				required: true,
				error: "",
				example: "Write a short email to the president about a math quiz.",
			},
		],
		example: {
			output: "",
			// outputs: [
			// 	"The sun is very old, over 4.5 billion years",
			// 	"At 10,000 degrees, sun is also very hot",
			// 	"Gasses called coronal mass ejections erupt from the sun",
			// ],
			// Icon: RefreshIcon,
			color: "blue",
		}
	}]
		
}

export default obj

