import jobad from './jobad';
import summarize from './summarize';
import codeInterpret from './interpret';
import intro from './intro';
import helloworld from './helloworld';
import example from './example';
import recipe from './recipe';
import resume from './resume';
import lazychatbot from './lazychatbot';
import review from './review';
import history from './history';

const TOOLS = [

	codeInterpret,
	intro,
	jobad,
	summarize,
	helloworld,
	example,
	recipe,
	resume,
	lazychatbot,
	review,
	history, 
]

export default TOOLS
